import React from "react"
import Layout from "../../components/layout"
import { Container, Row, Col, Alert } from "reactstrap"
import { StaticImage } from 'gatsby-plugin-image'
import 'bootstrap/dist/css/bootstrap.css'


let Pfirst = () => (
    <Layout>
        <Container className="py-5">
            <Row>
                <Col xs={12} md={3} className="mb-3">
                    <StaticImage src="../../data/product/p1.webp" />
                    <div style="line-height: 30px;"><span style="font-size: 16px;">道口壁挂报警器（型号：DKBJ-V-I）</span><br style="" /></div>
                </Col>
                <Col xs={12} md={9} className="mb-9">
                    <Alert color="primary">
                        产品详情
                    </Alert>
                    <ul>
                        <li>当列车接近道口时，发出声光语音报警信号；</li>
                        <li>道口发生故障时向火车发出报警信号；</li>
                        <li>配合外接道口综合控制台、4G设备可以在本地显示视频查询，画面清晰、流畅；</li>
                        <li>结构设计合理，易操作，外壳坚固耐用，美观大方；</li>
                        <li>配有报警、视频监管理客户端软件，人机交互界面，便于操作，为铁路管理部门管理用户档案、查询分析现场情况，提供有利依据；</li>
                        
                        </ul>
                        <Alert color="primary">
                        产品简介
                    </Alert>
                    <div class="detail_specific_desc detail_item_content show_detail_content">
                        
                        <p>&nbsp; &nbsp; “道口报警器”是我公司研制的新一代专用于铁路道口的多功能防护设备，是集火车接近时的声、光及语音报警、报警信号输出的多功能报警设备。配合我公司研制的道口综合控制台、视频设备，既能实现火车接近时的报警，又能实时4G无线视频传输、双向语音对讲、硬盘录像、视频监控铁路过车情况等功能于一体的全方位，为铁路管理部门监督掌控道口的运行情况提供了有效的依据。本系统不干扰铁路的原有设施设备，是道口实时管理、监控的理想产品。</p>
                        <p style="line-height:1.5em;"><br/></p></div>

                </Col>
            </Row>
        </Container>
    </Layout>
)

export default Pfirst





